<template>
  <div class="container">
    <div class="main">
      <el-table :data="tableData" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="id" label="ID" width="80"> </el-table-column>
        <el-table-column prop="name" label="名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="sensorCode" label="节点编号"> </el-table-column>
        <el-table-column prop="expression" label="表达式"> </el-table-column>
        <el-table-column prop="time" label="日期" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="toDevice" label="设备号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="msg" label="信息" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="command" label="指令" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-delete"
              @click="deletes(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagingBox">
        <el-pagination
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      total: 0,
      page: 1,
      limit: 10,
      size: 10,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$get("/conditionConfig/getHistoryList", {
        page: this.page,
        limit: this.size,
      }).then((res) => {
        if (res.data.state == "success") {
          let m = res.data.datas;
          for (let i = 0; i < m.length; i++) {
            if (m[i].state == 1) {
              m[i].state = true;
            } else {
              m[i].state = false;
            }
          }
          this.total = res.data.count;
          this.tableData = m;
        }
      });
    },
    tongfengState(id, state) {
      let status = 0;
      if (state == true) {
        status = 1;
      }
      this.$post("/conditionConfig/updateState", {
        id: id,
        state: status,
      }).then((res) => {
        if (res.data.state == "success") {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getList();
        }
      });
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/conditionConfig/deleteHistory", {
            id: id,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              if (this.size == this.total * 1 - 1) {
                this.page = 1;
              }
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },
    handleSizeChange(size) {
      this.size = size;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    handleSelectionChange() {},
  },
};
</script>
<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
.container {
  width: 100%;
  height: 91vh;
  padding: 1vh;
  box-sizing: border-box;
  overflow: hidden;
  color: #333;
  font-size: 12px;
}
.main {
  width: 100%;
  height: 89vh;
  overflow-y: auto;
  margin-top: 2.5vh;
  background: #fff;
}
.mainList {
  display: inline-block;
  width: 22%;
  height: 20vh;
  padding: 0.8vh 0.8vh 1.5vh 0.8vh;
  box-sizing: border-box;
  border: 1px solid green;
  border-radius: 6px;
  margin-top: 2vh;
  margin-left: 3%;
}
.mainList:nth-child(4n + 1) {
  margin-left: 0 !important;
}
.title {
  width: 100%;
  line-height: 4vh;
  font-size: 1.5vh;
  border-bottom: 1px solid #999;
}
.mainListWrap {
  display: flex;
  width: 100%;
}
.mainListWrapItem {
  flex: 1;
  height: 15vh;
}
.mainListWrapItem:nth-child(2) {
  padding-left: 1vh;
  box-sizing: border-box;
  border-left: 1px solid #999;
}
.mainListWrapItemName {
  margin-top: 1vh;
}
.mainListWrapItemTitle {
  margin-top: 1vh;
}
.el-pagination {
  margin-top: 2vh;
  width: 84vw;
  position: absolute;
  bottom: 3vh;
  text-align: center;
}
.main .el-table {
  width: 100%;
  height: 76vh;
  overflow-y: auto;
}
</style>
<style>
.container .el-table td,
.container .el-table th {
  text-align: center !important;
}
</style>
